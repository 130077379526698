import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { ChildDataProps, graphql } from 'react-apollo';
import gql from 'graphql-tag';

import IssuesList from './IssuesList/IssuesList';
import styles from './IssueDashboard.module.scss';
import { IssueType } from '../../types/Issue';
import IssueDetailContainer from './IssueDetail/IssueDetail';
import CenteredLoadingIcon from '../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon';
import { Contract, UserInfo, Building } from '../../types/UserInfo';

export const ISSUE_QUERY = gql`
  query GetIssues($contractId: String!, $status: String) {
    issues(contractId: $contractId, status: $status) {
      id
      title
      reportedDate
      finishedDate
      contractName
      address
      category {
        id
        name
        parentCategory {
          id
          name
        }
      }
      floor
      room
      priority
      status
      serviceDeskId
      contractId
      userInfo {
        name
      }
      comments {
        id
        issueId
        senderName
        senderUsername
        content
        created
        isSystemMessage
      }
      images {
        id
        commentId
        name
      }
      documents {
        id
        commentId
        name
      }
      updatesSinceLastViewed
    }

    issueCategories {
      id
      name
      subCategories {
        id
        name
      }
    }
  }
`;

const ISSUES_SUBSCRIPTION = gql`
  subscription IssueUpdates($contractId: String!, $username: String!) {
    issueUpdates(contractId: $contractId, username: $username) {
      type
      issue {
        id
        title
        reportedDate
        finishedDate
        address
        floor
        room
        priority
        status
        serviceDeskId
        contractId
        contractName
        userInfo {
          name
        }
        comments {
          id
          issueId
          senderName
          senderUsername
          content
          created
          isSystemMessage
        }
        images {
          id
          commentId
          name
        }
        documents {
          id
          commentId
          name
        }
        updatesSinceLastViewed
      }
    }
  }
`;

interface IssuesDashboardProps extends RouteComponentProps<{ id: string }> {
    issues: IssueType[];
    buildingId?: string;
    contractId?: string;
    userInfo: UserInfo;
    preferredBuilding: Building;
    onStatusUpdate: (status: string) => void;
    status: string;
}

class IssuesDashboard extends React.Component<IssuesDashboardProps> {

    render(): React.ReactNode {
        return (
            <div className={styles['issue-dashboard']}>
                <IssuesList
                    userInfo={this.props.userInfo}
                    contractId={this.props.contractId}
                    issues={this.props.issues}
                    basePath=""
                    status={this.props.status}
                    updateStatusCallback={(status) => {
                        this.setState({ status }, () => {
                            this.props.onStatusUpdate(status); // Notify parent about the status update
                        });
                    }}
                />
                <Route
                    exact
                    path="/issue/:id"
                    render={(routerProp) => (
                        <IssueDetailContainer
                            buildingId={this.props.buildingId}
                            preferredBuilding={this.props.preferredBuilding}
                            contractId={this.props.contractId}
                            {...routerProp}
                            basePath=""
                        />
                    )}
                />
            </div>
        );
    }
}

interface IssuesDashboardContainerProps extends RouteComponentProps<{ id: string }> {
    preferredContract: Contract;
    preferredBuilding: Building;
    userInfo: UserInfo;
}

class IssueDashboardContainer extends React.Component<ChildProps> {
    constructor(props) {
        super(props);

    }
    state = {
        status: "open"
    }

    private unsubscribe?: () => void;

    componentDidMount(): void {
        const {
            userInfo: { username },
            preferredContract: { contractId },
            data: { refetch, subscribeToMore },
        } = this.props;

        this.unsubscribe = subscribeToMore({
            document: ISSUES_SUBSCRIPTION,
            variables: { contractId, username },
            updateQuery: (prev) => {
                refetch();
                return prev;
            },
        });
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.preferredBuilding != prevProps.preferredBuilding) {
            this.setState({
                status: "open"
            })
        }
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) this.unsubscribe();
    }

    render(): React.ReactNode {
        const {
            userInfo,
            preferredContract: { contractId },
            preferredBuilding,
            data: { loading: issuesLoading, error: issuesError, issues },
            ...rest
        } = this.props;

        if (issuesLoading) return <CenteredLoadingIcon text="Laster saker" />;
        if (issuesError || issues == undefined) return <p>Error :(</p>;

        return (
            <IssuesDashboard
                {...rest}
                contractId={contractId}
                preferredBuilding={preferredBuilding}
                issues={issues}
                userInfo={userInfo}
                status={this.state.status}
                onStatusUpdate={(status) => {
                    this.props.data.refetch({ status, contractId }); // Trigger refetch with updated variables
                    this.setState({
                        status: status
                    })
                }}
            />
        );
    }
}

interface ResponseType {
    issues: IssueType[];
}

interface Variables {
    contractId: string;
    status: string;
}

type ChildProps = ChildDataProps<IssuesDashboardContainerProps, ResponseType, Variables>;

export default graphql<IssuesDashboardContainerProps, ResponseType, Variables, ChildProps>(ISSUE_QUERY, {
    options: (props: { preferredContract: Contract }) => ({
        variables: { contractId: props.preferredContract.contractId, status: 'open' }, // Default status
    }),
})(IssueDashboardContainer);
