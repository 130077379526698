import * as React from 'react';
import { NotificationBox, NotiBox } from './NotificationPanel';
import styles from './Notification.module.scss';
import { shape } from 'prop-types';
import { ReactComponent as FilterArrowDown } from '../../viewComponents/Icons/newDesign/Sorting-arrow-down.svg';
import { ReactComponent as FilterArrowUp } from '../../viewComponents/Icons/newDesign/Sorting-arrow-up.svg';
import FilterButton from "../../viewComponents/FilterButton/FilterButton";


enum SeverityLevel {
    "Critical",
    "Major",
    "Info"
}

export interface NotificationAttributes {
    Title: string,
    Teaser: string,
    Description: string,
    StartDate: string
    EndDate: string,
    Level: SeverityLevel,
    Created: string,
    DaysToShow: number,
    PublishDate: string
}

const getSPToken = async () => {
    let token = await fetch("/api/spservices/gettoken", {
        credentials: "include", method: "POST"
    })
        .then(response => response.text())
        .then(data => {
            return data
        });
    return token;
}

const getNotificationsFromSP = async (query: any) => {
    let token = await getSPToken();
    let items: any[] = [];

    const baseUrl = "https://strongholdinvest.sharepoint.com/sites/OnboardingClientsNorway/_api/web/Lists/getByTitle('Notifications')/items?$filter=";
    let filter;
    let timeQuery;

    for (const field in query) {
        let queryParam;
        if (field == "EndDate" || field == "StartDate" || field == "Created") {
            queryParam = field + " " + query[field];
            timeQuery = queryParam;
        }
        else {
            queryParam = field + " eq '" + query[field] + "'";
        }
        if (!filter) {
            filter = queryParam;
        }
        else {
            filter = filter + " and ";
            filter = filter + queryParam;
        }
    }
    //For getting all GLOBAL notifications in the same timeframe
    filter = filter + " or Building eq 'GLOBAL' and " + timeQuery;

    let res = await fetch(baseUrl + filter, {
        method: 'GET',
        headers: {
            'Content-Type': "application/json",
            Authorization: token,
            Accept: 'application/json;odata=verbose'
        }
    }).then(response => response.json())
        .then(data => {
            return data
        });
    if (res && res.d && res.d.results) {
        items = res.d.results;
    }
    items = items.map((i: any) => {
        let pubDate = new Date(i.StartDate);
        pubDate.setDate(pubDate.getDate() - i.DaysToShow);
        let temp: NotificationAttributes = {
            Title: i["Title"],
            Teaser: i["Teaser"],
            Description: i["Description"],
            StartDate: i["StartDate"],
            EndDate: i["EndDate"],
            Level: i["Level"],
            Created: i["Created"],
            DaysToShow: i["DaysToShow"],
            PublishDate: pubDate.toLocaleDateString()
        }
        return temp;
    })
    return items
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const Notification = (props: { building: any }) => {

    const [oppslag, setOppslag] = React.useState<NotificationAttributes[]>([]);
    const [activeBttn, setActiveBttn] = React.useState<string | undefined>(undefined);

    const createBoxes = async (query: any) => {
        const notificationsData = await getNotificationsFromSP(query);
        const notifications: any[] = [];

        notificationsData.sort((a: NotificationAttributes, b: NotificationAttributes) => {

            let aDate = new Date(a.StartDate);
            aDate.setDate(aDate.getDate() - a.DaysToShow);

            let bDate = new Date(b.StartDate);
            bDate.setDate(bDate.getDate() - a.DaysToShow);

            if (aDate.valueOf() < bDate.valueOf()) return -1;
            if (aDate.valueOf() > bDate.valueOf()) return 1;
            return 0;
        });

        notificationsData.forEach((notification: NotificationAttributes, i: any) => {
            notifications.push(<NotificationBox key={i} notificationData={notification} />)
        });
        return notifications;
    }

    const filter = async (name: string) => {

        setActiveBttn(name);

        let oppslag;

        const today = new Date();

        switch (name) {
            case 'old':
                oppslag = await createBoxes({ BuildingId: props.building.id, EndDate: "le datetime'" + today.toISOString() + "'" });
                break;
            case 'upcoming':
                oppslag = await createBoxes({ BuildingId: props.building.id, EndDate: "ge datetime'" + today.toISOString() + "'" });
                oppslag = oppslag.filter((notification: NotificationAttributes) => {

                    let ShowDate = new Date(notification.StartDate);
                    ShowDate.setDate(ShowDate.getDate() - notification.DaysToShow);

                    if (ShowDate.getTime() === today.getTime() || ShowDate < today) {
                        return true;
                    }
                    return false;
                });
                break;
            case 'current':
                oppslag = await createBoxes({ BuildingId: props.building.id, EndDate: "ge datetime'" + today.toISOString() + "'" });
                oppslag = oppslag.filter((notification: NotificationAttributes) => {

                    let ShowDate = new Date(notification.StartDate);
                    ShowDate.setDate(ShowDate.getDate() - notification.DaysToShow);

                    if (ShowDate.getTime() === today.getTime() || ShowDate < today) {
                        return false;
                    }
                    return true;
                });
                break;
        }
        setOppslag(oppslag);
    }

    if (!activeBttn) {
        filter('current');
    }

    React.useEffect(() => {
        filter('current');
    }, [props.building]);

    return (
        <div className={'ms-Grid ' + styles['list']} dir='ltr'>
            <div className='ms-Grid-row'>
                <p className={styles['caption']}>Oppslagstavle</p>
            </div>
            <div className='ms-Grid-row'>
                <div className={styles['filterTitle']}>Filtrer:</div>
                <FilterButton
                    className={styles["filterBttn"]}
                    id={"filter_current"}
                    value={"filter_current"}
                    checked={activeBttn == 'current'}
                    name={"filter_current"}
                    onClick={() => { filter("current") }}>
                    {`Nye meldinger`}
                </FilterButton>
                <FilterButton
                    className={styles["filterBttn"]}
                    id={"filter_old"}
                    value={"filter_old"}
                    checked={activeBttn == 'old'}
                    name={"filter_old"}
                    onClick={() => { filter("old") }}>
                    {`Gamle meldinger`}
                </FilterButton>
            </div>
            <div className='ms-Grid-row'>
                <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                    <div>
                        {oppslag.length > 0 && oppslag}
                        {oppslag.length == 0 && <p>Ingen Oppslag</p>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export const NotificationSidebar = (props: { building: any }) => {
    const { height, width } = useWindowDimensions();
    const [oppslag, setOppslag] = React.useState<any[] | undefined>(undefined);

    const [currentExpandedNot, setCurrentExpandedNot] = React.useState<any | undefined>(undefined);
    const [newExpandedNot, setNewExpandedNot] = React.useState<any | undefined>(undefined);

    let boxes = React.useRef<HTMLDivElement>(document.createElement("div"));
    let boxes2 = React.useRef<HTMLDivElement>(document.createElement("div"));
    let boxes3 = React.useRef<HTMLDivElement>(document.createElement("div"));

    let mainBox = React.useRef<HTMLDivElement>(document.createElement("div"));
    let oppslagBox = React.useRef<HTMLDivElement>(document.createElement("div"));
    let showBttn = React.useRef<HTMLDivElement>(document.createElement("div"));
    let hideBttn = React.useRef<HTMLDivElement>(document.createElement("div"));
    let lukkBttn = React.useRef<HTMLDivElement>(document.createElement("div"));
    let oppslg = React.useRef<HTMLDivElement[] | any>([]);

    const createBoxes = (notificationsData: any) => {
        let notifications: any[] = [];

        oppslg.current = notificationsData.map((ref: any, index: any) => oppslg.current[index] = React.createRef<HTMLDivElement>());

        notifications = notificationsData.map((x: NotificationAttributes, i: any) => {
            const props = { notificationData: x, view: setNewExpandedNot, index: i }
            return (<NotiBox key={i} props={props} ref={oppslg.current[i]} />);
        })
        return notifications;
    }

    const getNotifications = async () => {

        var today = new Date();
        let notifications = await getNotificationsFromSP({ BuildingId: props.building.id, EndDate: "ge datetime'" + today.toISOString() + "'" });

        //filter out notifications that shouldn't be shown yet
        notifications = notifications.filter((notification: NotificationAttributes) => {

            let ShowDate = new Date(notification.StartDate);
            ShowDate.setDate(ShowDate.getDate() - notification.DaysToShow);

            if (ShowDate.getTime() === today.getTime() || ShowDate < today) {
                return true;
            }
            return false;
        });
        //Sort by Severity
        notifications.sort((a: NotificationAttributes, b: NotificationAttributes) => {

            let aDate = new Date(a.StartDate);
            aDate.setDate(aDate.getDate() - a.DaysToShow);

            let bDate = new Date(b.StartDate);
            bDate.setDate(bDate.getDate() - a.DaysToShow);

            if (SeverityLevel[a.Level] < SeverityLevel[b.Level]) return -1;
            if (SeverityLevel[a.Level] > SeverityLevel[b.Level]) return 1;
            if (aDate.valueOf() < bDate.valueOf()) return -1;
            if (aDate.valueOf() > bDate.valueOf()) return 1;
            return 0;
        });
        return notifications;
    }

    const getBoxes = async () => {
        const data = await getNotifications();
        const boxes = createBoxes(data);
        setOppslag(boxes);
    }

    const showNotification = () => {
        const main = mainBox.current;
        const hide = hideBttn.current;
        const show = showBttn.current;
        const notBox = oppslagBox.current;

        const box = boxes.current;
        const box2 = boxes2.current;
        const box3 = boxes3.current;
        box.classList.remove(styles['hide']);
        box2.classList.remove(styles['hide2']);
        box3.classList.remove(styles['hide']);

        main.classList.remove(styles['minemize']);
        hide.classList.remove(styles['hide']);
        notBox.classList.remove(styles['hide']);
        show.classList.remove(styles['visible']);
    }

    const hideNotification = () => {
        setNewExpandedNot(currentExpandedNot);
        const main = mainBox.current;
        const hide = hideBttn.current;
        const notBox = oppslagBox.current;
        const show = showBttn.current;

        const box = boxes.current;
        const box2 = boxes2.current;
        const box3 = boxes3.current;
        box.classList.add(styles['hide']);
        box2.classList.add(styles['hide2']);
        box3.classList.add(styles['hide']);

        main.classList.add(styles['minemize']);
        hide.classList.add(styles['hide']);
        notBox.classList.add(styles['hide']);
        show.classList.add(styles['visible']);

    }


    const sendThis = (x: any) => {
        const lukk = lukkBttn.current;
        oppslg.current.forEach((element: any, index: any) => {
            if (x != index && index < x) {
                element.current.classList.add(styles['hidePanel']);
            }
            if (x == index) {
                element.current.classList.add(styles['focus']);
                element.current.children[1].classList.add(styles['hide']);
                element.current.children[2].classList.add(styles['descriptionShow']);
                element.current.children[3].classList.add(styles['hide']);
                element.current.children[4].classList.remove(styles['hide']);
                element.current.children[5].classList.remove(styles['hide']);
                lukk.classList.add(styles['visible']);
            }
        });
    }
    const closethis = (x: any) => {
        const lukk = lukkBttn.current;
        oppslg.current.forEach((element: any, index: any) => {
            if (x != index && index < x) {
                element.current.classList.remove(styles['hidePanel']);
            }
            if (x == index) {
                element.current.classList.remove(styles['focus']);
                element.current.children[1].classList.remove(styles['hide']);
                element.current.children[2].classList.remove(styles['descriptionShow']);
                element.current.children[3].classList.remove(styles['hide']);
                element.current.children[4].classList.add(styles['hide']);
                element.current.children[5].classList.add(styles['hide']);
                lukk.classList.remove(styles['visible']);
            }
        });
    }

    if (!oppslag) {
        getBoxes();
    }

    const handleChildElementClick = (e) => {
        e.stopPropagation()
    }

    React.useEffect(() => {
        setNewExpandedNot(currentExpandedNot);
        getBoxes();
    }, [props.building]);

    React.useEffect(() => {
        if (newExpandedNot != undefined) {
            if (currentExpandedNot == newExpandedNot) {
                closethis(newExpandedNot);
                setCurrentExpandedNot(undefined);
            }
            else if (currentExpandedNot == undefined && newExpandedNot != undefined) {
                sendThis(newExpandedNot);
                setCurrentExpandedNot(newExpandedNot);
            }
            else if (currentExpandedNot != undefined && newExpandedNot != currentExpandedNot) {
                closethis(currentExpandedNot);
                sendThis(newExpandedNot);
                setCurrentExpandedNot(newExpandedNot);
            }
            setNewExpandedNot(undefined);
        }
    }, [newExpandedNot])

    return (
        <div className={"ms-grid " + styles['sidebar'] + " " + (width < 769 ? styles['minemize'] : "")} dir='ltr' ref={mainBox}>
            <div className={styles['boxes'] + " ms-grid-row " + (width < 769 ? styles['hide'] : "")} ref={boxes}>
                <div className={styles['HideBttnBox'] + ' ms-Grid-col ms-sm4 ms-md4 ms-lg4 ' + (width < 769 ? styles['hide'] : "")} ref={hideBttn}><button className={styles['button']} onClick={hideNotification}>Skjul viktige meldinger &gt;</button></div>
            </div>
            <div className={styles['boxes2'] + " ms-grid-row " + (width < 769 ? styles['hide2'] : "")} ref={boxes2} onClick={() => { setNewExpandedNot(currentExpandedNot) }}>
                <div className={styles['ShowBttnBox'] + ' ms-Grid-col ms-sm4 ms-md4 ms-lg4 ' + (width < 769 ? styles['visible'] : "")} ref={showBttn} onClick={showNotification}><button className={styles['buttonShow']} onClick={showNotification}>Vis viktige meldinger</button></div>
                {oppslag && oppslag.length > 0 && <div className={styles['NotBox'] + ' ms-Grid-col ms-sm4 ms-md4 ms-lg4 ' + (width < 769 ? styles['hide'] : "")} ref={oppslagBox} onClick={(e) => handleChildElementClick(e)}>
                    {oppslag}
                </div>}
                {oppslag != undefined && oppslag.length == 0 && <div className={styles['NotBox'] + ' ms-Grid-col ms-sm4 ms-md4 ms-lg4 ' + (width < 769 ? styles['hide'] : "")} ref={oppslagBox}>
                    <p>Ingen Oppslag</p>
                </div>}
            </div>
            <div className={styles['boxes3'] + " ms-grid-row " + (width < 769 ? styles['hide'] : "")} ref={boxes3} onClick={() => { setNewExpandedNot(currentExpandedNot) }}>
                <div className={styles['lukkBox'] + ' ms-Grid-col ms-sm4 ms-md4 ms-lg4'} ref={lukkBttn} onClick={() => { setNewExpandedNot(currentExpandedNot) }}>
                    <a>&and; <br />Vis alle Meldinger</a>
                </div>
            </div>
        </div>
    );


}