import * as React from "react"
import { getServicesFromSP, getSPToken, getSPImage } from "./GetServices"
import * as H from 'history';
import { IService } from "./IService";
import styles from "../Services/Services.module.scss";
import { Mutation, MutationFn, Query } from "react-apollo";
import { gql } from "apollo-boost";
import { IssueInput } from "../../types/Issue";
import ImageUpload, { IFileUpload } from "../ImageUpload/ImageUpload";
import { ReactComponent as Stepper1 } from "../../viewComponents/Icons/newDesign/Stepper-1.svg";
import { ReactComponent as Stepper2 } from "../../viewComponents/Icons/newDesign/Stepper 2.svg";
import { ReactComponent as Stepper3 } from "../../viewComponents/Icons/newDesign/Stepper 3.svg";
import { ReactComponent as StepperDone } from "../../viewComponents/Icons/newDesign/Stepper done.svg";
import { ReactComponent as Dots } from "../../viewComponents/Icons/newDesign/Stepper-dots.svg";
import DocumentUpload from "../DocumentUpload/DocumentUpload";
import history from '../../History'
import { userIsOwner, buildingsForUser, buildingFromBuildingId } from "../../tools/userUtils";
import SelectInput from "../../viewComponents/SelectInput/SelectInput";
import { Building, UserInfo, Contract } from "../../types/UserInfo";

export let Services = (props: { history: H.History<H.LocationState>, userInfo, preferredBuilding, preferredContract, preferredCompany }) => {
    const [services, setServices] = React.useState([])
    const [categories, setCategories] = React.useState([])
    const [spToken, setSPToken] = React.useState()
    const [selectedService, setSelectedService] = React.useState()

    const getData = async () => {
        let services = await getServicesFromSP();
        setServices(services)
        if (services && services.length) {
            let cats = services.map((s: IService) => { return s.Category }).filter((v, i, a) => a.indexOf(v) === i)
            setCategories(cats)
        }
    }
    React.useEffect(() => {
        getData()
    }, [])

    React.useEffect(() => {
        getSPToken().then(t => {
            setSPToken(t)
        })
    }, [])

    const serviceSelectedCallback = (s: IService) => {
        setSelectedService(s)
    }

    return <span>
        {!selectedService &&
            categories.map((cat, index) => {
                return <div key={index} style={{ textAlign: "center" }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8" >
                        <div className={styles.servicesWrapper} >
                            <span className="headlineS" > {cat} </span>
                            {
                                services.filter((s: IService) => s.Category == cat).map((s: IService, index) => {
                                    return <ServiceCard serviceSelectedCallback={serviceSelectedCallback} index={index} s={s} spToken={spToken} key={index} history={props.history} />
                                })
                            }
                        </div>
                    </div>
                </div>
            })
        }
        {
            selectedService && <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8" >
                <div className={styles.servicesWrapper}>
                    <ServiceForm service={selectedService} spToken={spToken} preferredBuilding={props.preferredBuilding} resetStateCallBack={() => { setSelectedService(undefined) }} userInfo={props.userInfo} preferredContract={props.preferredContract} history={props.history} setSelectedService={setSelectedService} />
                </div>
            </div>}
    </span>
}

const ServiceCard = (props: { s: IService, index, spToken, history, serviceSelectedCallback }) => {
    const [image, setImage] = React.useState();

    React.useEffect(() => {
        if (props.s && props.s.Image) {
            let imageJson = JSON.parse(props.s.Image)
            let url = `${imageJson["serverUrl"]}/sites/OnboardingClientsNorway/_api/web/GetFileByServerRelativeUrl('${imageJson["serverRelativeUrl"]}')/$value`
            getSPImage(props.spToken, url).then(blob => {
                setImage(URL.createObjectURL(blob))
            })
        }
    }, [props.s])

    const onClickGoToOrder = () => {
        props.serviceSelectedCallback(props.s)
    }

    return <div className="" key={props.index} >
        <div className="bigCard" key={props.index} >
            <div className="imageWrapper" >
                <img src={image} />
                {
                    props.s.New && <span className={styles.newServiceBadge}> NY TJENESTE </span>}
                {
                    props.s.Popular && !props.s.New && <span className={styles.popularServiceBadge}> POPULÆR </span>}
                <div className="cardBack" onClick={() => {
                    onClickGoToOrder()
                }}>
                    {
                        props.s.ShortDescription && <p className="shortDescription"> {props.s.ShortDescription} </p>}
                    <button >BESTILL</button>
                </div>
            </div>
            <span className="cardTitle" > {props.s.Title} </span>
        </div>
        <span > </span>
    </div>
}

const ServiceForm = (props: { service: IService, spToken, preferredBuilding, userInfo, preferredContract, history, setSelectedService, resetStateCallBack }) => {
    const [page, setPage] = React.useState(1);
    const [lastValidPage, setLastValidPage] = React.useState(1);
    const [service, setService] = React.useState(props.service)
    const [image, setImage] = React.useState();
    const [desc, setDesc] = React.useState("")
    const [chosenBuilding, setChosenBuilding] = React.useState<Building>(props.preferredBuilding)
    const [floor, setFloor] = React.useState("")
    const [room, setRoom] = React.useState("")
    const [showFloor, setShowFloor] = React.useState(false)
    const [showRoom, setShowRoom] = React.useState(false)
    const [requireFloor, setRequireFloor] = React.useState(false)
    const [requireRoom, setRequireRoom] = React.useState(false)
    const [serviceTime, setServiceTime] = React.useState("")
    const [imageAttachments, setImageAttachments] = React.useState<IFileUpload[]>([])
    const [documentAttachments, setDocumentAttachments] = React.useState<IFileUpload[]>([])
    const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);

    const [validationActive, setValidationActive] = React.useState(false)
    //validation messages
    const [floorMessage, setFloorMessage] = React.useState("")
    const [roomMessage, setRoomMessage] = React.useState("")
    const [descMessage, setDescMessage] = React.useState("")
    const [serviceTimeMessage, setServiceTimeMessage] = React.useState("")

    const resetState = () => {
        props.resetStateCallBack()
        setPage(1);
        setLastValidPage(1)
        setDesc("")
        setFloor("")
        setRoom("")
        setImageAttachments([])
        setDocumentAttachments([])
        setShowRoom(false)
        setShowFloor(false)
        setRequireFloor(false)
        setRequireRoom(false)
        setValidationActive(false)
        setFloorMessage("")
        setRoomMessage("")
        setDescMessage("")
        setServiceTime("")
        setChosenBuilding(props.preferredBuilding)
    }

    const buildingDropdownContent = buildingsForUser(props.userInfo).map(building => {
        return { id: building.id, displayValue: building.street }
    })

    const onBuildingChange = (event: React.FormEvent<HTMLSelectElement>, userInfo: UserInfo) => {
        setChosenBuilding(buildingFromBuildingId(event.currentTarget.value, userInfo))
    }

    React.useEffect(() => {
        if (service && service.Image) {
            let imageJson = JSON.parse(service.Image)
            let url = `${imageJson["serverUrl"]}/sites/OnboardingClientsNorway/_api/web/GetFileByServerRelativeUrl('${imageJson["serverRelativeUrl"]}')/$value`
            getSPImage(props.spToken, url).then(blob => {
                setImage(URL.createObjectURL(blob))
            })
        }
    }, [props.service])

    React.useEffect(() => {
        setChosenBuilding(props.preferredBuilding)
    }, [props.preferredBuilding])

    const onSubmitForm = (createIssue: MutationFn) => {
        let newIssue: IssueInput = {
            title: service.Title,
            buildingId: chosenBuilding.id,
            floor: floor,
            room: room,
            priority: 2,
            category: "",
            serviceDeskCategoryId: service.ZendeskID,
            descriptionComment: {
                sender: props.userInfo.username,
                content: desc,
                imageIds: imageAttachments.map(f => f.id),
                documentIds: documentAttachments.map(d => d.id),
            },
            serviceRequestedTime: serviceTime,
            isService: true,
            contractId: chosenBuilding.contracts[0].contractId
        }

        createIssue({
            variables: newIssue
        }).then((result) => {
            console.log("issue created", result)
            setPage(4)
        })
    }

    const validateForm = () => {
        let floorInvalid = requireFloor && floor.length < 1
        let roomInvalid = requireRoom && room.length < 1
        let serviceTimeInvalid = serviceTime.length < 1
        let descInvalid = desc.length < 1
        if (floorInvalid) {
            setFloorMessage("Dette feltet er ikke utfylt korrekt!")
        } else { setFloorMessage("") }
        if (roomInvalid) {
            setRoomMessage("Dette feltet er ikke utfylt korrekt!")
        } else { setRoomMessage("") }
        if (serviceTimeInvalid) {
            setServiceTimeMessage("Dette feltet er ikke utfylt korrekt!")
        } else { setServiceTimeMessage("") }
        if (descInvalid) {
            setDescMessage("Dette feltet er ikke utfylt korrekt!")
        } else { setDescMessage("") }
        if (floorInvalid || roomInvalid || serviceTimeInvalid || descInvalid) {
            return false
        } else {
            return true
        }
    }

    React.useEffect(() => {
        if (validationActive) {
            validateForm()
        }
    }, [room, floor, serviceTime, desc, validationActive])

    return <Mutation mutation={CREATE_ISSUE_MUTATION} ignoreResults={true} >
        {(createIssue) => (
            <div className={styles.ServiceFormWrapper} > {
                service && <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                        {
                            page == 1 && <div>
                                <span className={`${[styles.serviceFormHeader, styles.page1Header].join(" ")}`}> FORESPØRSEL OM TJENESTE </span>
                                <span className={styles.page1MidRow}>
                                    <img className={styles.page1Image} src={image} />
                                    <div className={styles.page1BodyWrapper} >
                                        <span className={styles.page1Title}> {service.Title} </span>
                                        <span className={styles.page1Desc} dangerouslySetInnerHTML={{ __html: service.Longdescription || service.ShortDescription || '' }}></span>
                                    </div>
                                </span>
                                <div className={styles.page1BtnWrapper} >
                                    <span onClick={() => { setPage(2) }} className="brightBtn" > SEND FORESPØRSEL OM {service.Title} </span>
                                    <span onClick={
                                        () => {
                                            props.setSelectedService(undefined)
                                        }
                                    } className="fadedBtn" > GÅ TILBAKE TIL TJENESTER </span>
                                </div>
                            </div>}
                        {
                            page == 2 && <div className={styles.page2Wrapper}>
                                <span className={styles.page2Title}> Hva skal utføres ? </span>
                                <span className={styles.page2Address} > {chosenBuilding ? chosenBuilding.street : ""} </span>
                                <span className={styles.formLabel} > Beskrivelse </span>
                                <textarea id="" name="" value={desc} onChange={(event) => { setDesc(event.target.value) }} />
                                <span className={`${styles.validationMessageWrapper} validationError`}>{descMessage}</span>

                                <span className={styles.page2Title}> Hvor ? </span>
                                {userIsOwner(props.userInfo) && (
                                    <div className={styles['flex-left-column']}>
                                        <div className={styles['select-dropdown']}>
                                            <label htmlFor={"building-select"} className={styles.formLabel}>Bygg</label>
                                            <SelectInput name={"building-id"}
                                                id={"building-select"}
                                                optionsWithId={buildingDropdownContent}
                                                onChange={(e) => onBuildingChange(e, props.userInfo)}
                                                className="fullWidth"
                                                selectedValueId={chosenBuilding ? chosenBuilding.id.toString() : undefined} />
                                        </div>
                                    </div>
                                )}
                                {!userIsOwner(props.userInfo) && <span>
                                    <span className={styles.formLabel} > Bygning * </span>
                                    <input className={styles.singleInput} type="text" disabled value={props.preferredBuilding ? props.preferredBuilding["name"] : ""} onChange={() => { }} />
                                </span>}
                                <span className={styles.wrapper50}>
                                    <span className={styles.formLabel} > Etasje </span>
                                    <input className={styles.singleInput} value={floor} onChange={(floor) => {
                                        setFloor(floor.target.value)
                                    }}></input>
                                    <span className={`${styles.validationMessageWrapper} validationError`}>{floorMessage}</span>
                                </span>
                                <span className={styles.wrapper50MarginLeft}>
                                    <span className={styles.formLabel} > Rom </span>
                                    <input className={styles.singleInput} value={room} onChange={(room) => {
                                        setRoom(room.target.value)
                                    }}></input>
                                    <span className={`${styles.validationMessageWrapper} validationError`}>{roomMessage}</span>
                                </span>
                                <span className={styles.page2Title} > Når ? </span>
                                <span className={styles.formLabel} > Ønsket tidspunkt </span>
                                <span className={styles.smallDescription} > Oppgi ønsket måned eller ukenummer, så skal vi prøve å utføre jobben innenfor dette tidsrommet.</span>
                                <textarea id="" name="" value={serviceTime} onChange={(event) => { setServiceTime(event.target.value) }} />
                                <span className={`${styles.validationMessageWrapper} validationError`}>{serviceTimeMessage}</span>
                                <span className={styles.imageUploadWrapper}><ImageUpload className={styles["upload-input"]}
                                    onChange={images => setImageAttachments(images)}
                                    disabled={false} />
                                </span>
                                <span className={styles.imageUploadWrapper}><DocumentUpload className={styles["upload-input"]}
                                    onChange={docs => setDocumentAttachments(docs)}
                                    disabled={false} />
                                </span>
                                <div className={styles.page2BtnWrapper} >
                                    <span onClick={() => {
                                        let isValid = validateForm()
                                        setValidationActive(true);
                                        if (isValid) {
                                            setPage(3)
                                            setLastValidPage(2)
                                        }
                                    }} className="brightBtn" > SE OPPSUMMERING </span>
                                    {/* <span className="fadedBtn" onClick={() => { saveFormToBrowser() }}> AVSLUTT UTEN Å SENDE </span> */}
                                </div>
                            </div>}
                        {
                            page == 3 && <div className={styles.page3Wrapper}>
                                <span className={styles.page2Title}> Oppsummering </span>
                                <span className={styles.summaryBox} >
                                    <span className={styles.leftTitle}> {service.Title} </span>
                                    <span className="filterBtn">{service.Category}</span>
                                    <span className={styles.summaryDesc} > {desc} </span>
                                    <span className={styles.formLabel} > {chosenBuilding ? chosenBuilding.street : ""}{floor ? `, etg ${floor}` : ""} {room ? `, rom ${room}` : ""} </span>
                                    {/* <span className={styles.registeredLabel} > Registrert xx.zz.yyyy kl.ii.ii </span> */}
                                </span>
                                <div className={styles.page2BtnWrapper} >
                                    <span onClick={
                                        () => {
                                            if (!isSubmittingForm) {
                                                setIsSubmittingForm(true);
                                                onSubmitForm(createIssue)
                                            }
                                        }
                                    } className={isSubmittingForm ? "disabledBrightBtn" : "brightBtn"} >SEND INN FORESPØRSEL</span>
                                    <span className="fadedBtn" onClick={() => { setPage(2) }}>GJØR ENDRINGER</span>
                                    {/* <span className="fadedBtn" onClick={() => { saveFormToBrowser() }}> AVSLUTT UTEN Å SENDE </span> */}
                                </div>
                            </div>}
                        {
                            page == 4 && <div className={styles.page4Wrapper}>
                                <span className={styles.titleCompressed}> FORESPØRSEL ER REGISTRERT </span>
                                <span className={styles.confirmationSubHeaderWrapper} >
                                    <span className={styles.confirmationSubHeader}> Vi prøver å komme tilbake til deg så fort som mulig.</span>
                                    <span className={styles.confirmationSubHeader} > Du kan følge opp sforespørselen og legge til ny informasjon under fanen «Tjenester».</span>
                                </span>
                                <span className={styles.summaryBox} >
                                    <span className={styles.leftTitle}> {service.Title} </span>
                                    <span className={styles.summaryDesc} > {desc} </span>
                                    <span className={styles.formLabel} > {chosenBuilding ? chosenBuilding.street : ""}{floor ? `, etg ${floor}` : ""} {room ? `, rom ${room}` : ""} </span>
                                    {/* <span className={styles.registeredLabel} > Registrert xx.zz.yyyy kl.ii.ii </span> */}
                                </span>
                                <div className={styles.page2BtnWrapper} >
                                    <span onClick={
                                        () => {
                                            // history.push("/serviceorders")
                                            location.href = location.protocol + "//" + location.host + "/serviceorders"
                                        }
                                    } className="brightBtn" > SE OVERSIKT OVER ALLE DINE TJENESTER </span>
                                    <span className={["fadedBtn", styles.marginRight24].join(" ")} onClick={() => { resetState() }}> BESTILL NY TJENESTE </span>
                                </div>
                            </div>}

                    </div>

                    {
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" >
                            {page != 4 ? <Stepper1 onClick={() => { setPage(1) }} className={[styles.link, styles.circledNumber, styles.circle1, page == 1 ? styles.activeCircleBtn : "", page > 1 ? styles.doneStepCircle : ""].join(" ")}></Stepper1> :
                                <StepperDone className={[styles.circledNumber, styles.circle1, styles.doneStepCircle].join(" ")}></StepperDone>}
                            <Dots className={[styles["dots1"], page > 1 ? styles.doneStepCircle : ""].join(" ")}></Dots>
                            {page != 4 ? <Stepper2 onClick={() => { if (lastValidPage < 2) { return; } setPage(2) }} className={[lastValidPage > 1 ? styles.link : "", styles.circledNumber, styles.circle2, page == 2 ? styles.activeCircleBtn : "", page > 2 ? styles.doneStepCircle : ""].join(" ")}></Stepper2> :
                                <StepperDone className={[styles.circledNumber, styles.circle2, styles.doneStepCircle].join(" ")}></StepperDone>}
                            <Dots className={[styles["dots2"], page > 2 ? styles.doneStepCircle : ""].join(" ")}></Dots>
                            {page != 4 ? <Stepper3 onClick={() => { if (lastValidPage < 3) { return; } setPage(3) }} className={[lastValidPage > 2 ? styles.link : "", styles.circledNumber, styles.circle3, page == 3 ? styles.activeCircleBtn : "", page > 3 ? styles.doneStepCircle : ""].join(" ")}></Stepper3> :
                                <StepperDone className={[styles.circledNumber, styles.circle3, styles.doneStepCircle].join(" ")}></StepperDone>}
                        </div>
                    }
                </div>}
            </div>
        )
        }
    </Mutation>

}

const CREATE_ISSUE_MUTATION = gql`
    mutation CreateIssue($title: String!, $buildingId: String!, $floor: String, $room: String, $priority: Int!, $contractId: String, $descriptionComment: CommentInput!, $serviceRequestedTime: String, $serviceDeskCategoryId: String) {
        createIssue(issue: { 
            title: $title,
            buildingId: $buildingId, 
            floor: $floor, 
            room: $room, 
            priority: $priority, 
            category: "", 
            contractId: $contractId,
            descriptionComment: $descriptionComment,
            isService: true,
            serviceRequestedTime: $serviceRequestedTime,
            serviceDeskCategoryId: $serviceDeskCategoryId
        }) {
            status
            message
        }
    }
`