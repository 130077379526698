import * as React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { Route, RouteComponentProps } from 'react-router';

import { IssueType } from '../../../types/Issue';
import IssuesList from '../../IssuesDashboard/IssuesList/IssuesList';
import IssueDetailContainer from '../../IssuesDashboard/IssueDetail/IssueDetail';
import CenteredLoadingIcon from '../../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon';
import { UserInfo, Building } from '../../../types/UserInfo';

export const ISSUES_FOR_BUILDING_QUERY = gql`
    query GetIssues($buildingId: String!, $status: String) {
        issuesForBuilding(buildingId: $buildingId, status: $status) {
            id
            title
            reportedDate
            finishedDate
            address
            floor
            room
            priority
            status
            serviceDeskId
            contractId
            contractName
            userInfo {
                name
            }
        }
    }
`;

const ISSUES_SUBSCRIPTION_ON_BUILDING = gql`
    subscription IssueUpdates($buildingId: String!, $username: String!, $status: String) {
        issueUpdates(buildingId: $buildingId, username: $username, status: $status) {
            type
            issue {
                id
                title
                reportedDate
                finishedDate
                address
                floor
                room
                priority
                status
                serviceDeskId
                contractId
                contractName
                userInfo {
                    name
                }
            }
        }
    }
`;

interface Props {
    issues: IssueType[];
    buildingId: string;
    subscribeToMore: Function;
    userInfo: UserInfo;
    preferredBuilding: Building;
    status: string;
    onStatusUpdate: (status: string) => void;
}

class BuildingOwnerIssues extends React.Component<Props> {
    componentDidMount(): void {
        const { buildingId, userInfo, status, subscribeToMore } = this.props;

        subscribeToMore({
            document: ISSUES_SUBSCRIPTION_ON_BUILDING,
            variables: { buildingId, username: userInfo.username, status },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;

                const issueUpdate = subscriptionData.data.issueUpdates;

                switch (issueUpdate.type) {
                    case 'CREATED':
                        return {
                            ...prev,
                            issuesForBuilding: [issueUpdate.issue, ...prev.issuesForBuilding],
                        };
                    case 'UPDATED':
                        const index = prev.issuesForBuilding.findIndex(
                            (i: IssueType) => i.id === issueUpdate.issue.id
                        );
                        if (index === -1) return prev;

                        const newIssuesList = [...prev.issuesForBuilding];
                        newIssuesList[index] = issueUpdate.issue;

                        return {
                            ...prev,
                            issuesForBuilding: newIssuesList,
                        };
                    default:
                        return prev;
                }
            },
        });
    }

    render(): React.ReactNode {
        const { issues, buildingId, preferredBuilding, userInfo, onStatusUpdate } = this.props;

        return (
            <>
                <IssuesList
                    issues={issues}
                    basePath={`/building/${buildingId}/issues`}
                    userInfo={userInfo}
                    status={this.props.status}
                    updateStatusCallback={(status) => onStatusUpdate(status)}
                />
                <Route
                    path="/building/:buildingId/issues/issue/:id"
                    render={(routeProps) => (
                        <IssueDetailContainer
                            buildingId={buildingId}
                            {...routeProps}
                            basePath={`/building/${buildingId}/issues`}
                            preferredBuilding={preferredBuilding}
                        />
                    )}
                />
            </>
        );
    }
}

interface Params {
    buildingId: string;
}

interface BuildingOwnerIssuesContainerProps extends RouteComponentProps<Params> {
    userInfo: UserInfo;
    preferredBuilding: Building;
}

interface State {
    status: string;
}

class BuildingOwnerIssuesContainer extends React.Component<
    BuildingOwnerIssuesContainerProps,
    State
    > {
    constructor(props: BuildingOwnerIssuesContainerProps) {
        super(props);
        this.state = {
            status: 'open',
        };
    }

    handleStatusUpdate = (newStatus: string, refetch: Function) => {
        const { buildingId } = this.props.match.params;

        this.setState({ status: newStatus }, () => {
            refetch({ buildingId, status: newStatus });
        });
    };

    handleBuildingChange = (refetch: Function) => {
        const { buildingId } = this.props.match.params;
        const { status } = this.state;

        refetch({ buildingId, status });
    };

    render(): React.ReactNode {
        const { match, userInfo, preferredBuilding } = this.props;
        const { status } = this.state;

        const variables = {
            buildingId: match.params.buildingId,
            status,
        };

        return (
            <Query
                query={ISSUES_FOR_BUILDING_QUERY}
                variables={variables}
                partialRefetch={true}
            >
                {({ loading, error, data, subscribeToMore, refetch }) => {
                    // Trigger refetch when preferredBuilding changes
                    if (this.props.preferredBuilding !== preferredBuilding) {
                        this.handleBuildingChange(refetch);
                    }

                    if (loading) return <CenteredLoadingIcon text="Laster saker" />;
                    if (error) return <span>Error :(</span>;

                    return (
                        <BuildingOwnerIssues
                            issues={data.issuesForBuilding}
                            buildingId={match.params.buildingId}
                            preferredBuilding={preferredBuilding}
                            userInfo={userInfo}
                            status={status}
                            onStatusUpdate={(newStatus) => this.handleStatusUpdate(newStatus, refetch)}
                            subscribeToMore={subscribeToMore}
                        />
                    );
                }}
            </Query>
        );
    }
}

export default BuildingOwnerIssuesContainer;
